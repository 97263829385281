import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { scrollInputToCenter } from '../../../utils/helpers/common';
import {
  StyledField,
  StyledTextField,
  StyledTextLabel,
  TextFieldContainer
} from './styled';
import { useToggleInputValueVisible } from './hooks/useToggleInputValueVisible';
import { VISIBLE_VALUE_ICON_SIZE } from './constants';
import EyeIcon from '../../../public/static/images/eye.svg';
import CloseEyeIcon from '../../../public/static/images/closed-eye.svg';
import { useTextFieldChange } from '../../../hooks/useTextFieldChange';

export const TextField = ({
  stat,
  name,
  cypress,
  icon,
  value,
  format,
  onClick,
  disabled = false,
  inputMode = 'text',
  onChange,
  maxLength,
  placeholder,
  isValueHidden,
  isFloat = false,
  styleType = 'default',
  type = 'text',
  formValues = {}
}) => {
  const isNum = inputMode === 'numeric';
  const numRegex = isFloat ? '[0-9.,]*' : '[0-9]*';
  const inputRef = useRef();
  const { isValueVisible, toggleValueVisibility } =
    useToggleInputValueVisible(inputRef);
  const onChangeTextField = useTextFieldChange(formValues);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (!value) setIsFocused(false);
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement?.addEventListener('focus', handleFocus);
      inputElement?.addEventListener('blur', handleBlur);

      return () => {
        inputElement?.removeEventListener('focus', handleFocus);
        inputElement?.removeEventListener('blur', handleBlur);
      };
    }
  }, []);

  const handleChange = e => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (typeof onChange === 'function') {
      onChange(e, name, isNum, isFloat);
      return;
    }
    onChangeTextField(e, name, isNum, isFloat);
  };

  const parseValue = val => (isNum && isFloat ? val.replace(',', '.') : val);

  const handleClick = () => {
    if (onClick) onClick();
    handleFocus();
  };

  const scrollHandler = () => scrollInputToCenter(inputRef.current);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (inputRef.current) {
        const { length } = inputRef?.current?.value;

        inputRef.current.setSelectionRange(length, length);
      }
    });
  }, [type]);
  const dataCy = cypress || 'text-field';

  return (
    <TextFieldContainer data-cy={`${dataCy}-container`}>
      <StyledTextField
        data-stat={stat}
        data-cy="text-field"
        onClick={handleClick}
        onFocus={handleFocus}
      >
        <StyledTextLabel
          role="presentation"
          data-cy={`${dataCy}-label`}
          $isBold={styleType === 'bold'}
          isActive={isFocused || !!value}
        >
          {placeholder}
        </StyledTextLabel>
        <StyledField
          name={name}
          value={value}
          ref={inputRef}
          format={format}
          formatOnBlur={isFloat}
          component="input"
          autoComplete="off"
          disabled={disabled}
          maxLength={maxLength}
          onFocus={scrollHandler}
          onChange={handleChange}
          data-cy={cypress || 'text-field-input'}
          $isBold={styleType === 'bold'}
          pattern={isNum ? numRegex : null}
          parse={isNum ? parseValue : v => v}
          inputMode={isFloat ? 'decimal' : inputMode}
          type={isValueHidden && !isValueVisible ? 'password' : type}
        />
      </StyledTextField>
      {icon && (
        <Icon IconComponent={icon} width={35} height={35} indent={false} />
      )}
      {isValueHidden && value && (
        <Icon
          color="transparent"
          indent={false}
          width={VISIBLE_VALUE_ICON_SIZE}
          height={18}
          IconComponent={isValueVisible ? EyeIcon : CloseEyeIcon}
          onClick={toggleValueVisibility}
        />
      )}
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  icon: PropTypes.func,
  onChange: PropTypes.func,
  stat: PropTypes.string,
  cypress: PropTypes.string,
  format: PropTypes.func,
  isFloat: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isValueHidden: PropTypes.bool,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(['default', 'bold']),
  inputMode: PropTypes.oneOf(['text', 'numeric']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  formValues: PropTypes.object
};
