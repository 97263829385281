import { useForm } from 'react-final-form';
import { ChangeEvent } from 'react';
import { validateNumField } from '../utils/helpers/validateItemForm';

// eslint-disable-next-line
export const useTextFieldChange = (formValues: Record<string, any>) => {
  const { change } = useForm();

  return (
    e: ChangeEvent<HTMLInputElement>,
    name: string,
    isNum: boolean,
    isFloat: boolean
  ) => {
    const { value, key } = e.target;
    if (isNum) {
      const { isValid, val } = validateNumField(isFloat, { value, key });
      if (isValid) change(formValues[name], val);
      else e.preventDefault();
    } else change(formValues[name], value);
  };
};
